import React, { memo, useEffect, useState } from 'react'
import moment from 'moment'
import { axiosRequest } from 'clients/api'
import { generateImageUrl } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader, Image } from 'components/common'
import './index.css'

const PreviewMedia = memo(({ isOpen, close, id, isLoading }) => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [branches, setBranches] = useState(null)
  const [customerTypes, setCustomerTypes] = useState(null)

  const getAccountNames = (promotion, type) => {
    return promotion.accounts
      .filter((account) => account.type === type)
      .map((account) => account.name)
      .join(', ')
  }

  const fetchMedia = () => {
    axiosRequest('get', `/promotion/${id}`)
      .then((response) => {
        const { promotion } = response.data
        setData(promotion)

        setBranches(getAccountNames(promotion, 'branch'))
        setCustomerTypes(getAccountNames(promotion, 'customer'))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    fetchMedia()
    return () => {
      setData(null)
    }
  }, [id])

  return (
    <Dialog
      open={isOpen}
      close={close}
      title={data?.title}
      actions={[
        {
          text: t('Close'),
          variant: 'tertiary'
        }
      ]}
      className="preview-media-dialog"
    >
      {isLoading && <Loader linear />}
      {data !== null && (
        <div className="modal-body">
          <UserInfo data={data} t={t} />
          <DateInfo data={data} t={t} />
          <div className="line" />
          <RecipientsInfo
            data={data}
            t={t}
            branches={branches}
            customerTypes={data.customer_types}
            customerServices={data.customer_services}
          />
          {data.media_library.length > 0 && <MediaLibrary data={data} />}
        </div>
      )}
    </Dialog>
  )
})

const UserInfo = ({ data, t }) => (
  <div>
    <p>
      <strong>{t('Created by')}: </strong>
      {data?.user?.user_detail?.full_name}
    </p>
    <p>
      <strong>{t('Title')}: </strong>
      {data?.user?.user_detail?.job_title}
    </p>
    <p>
      <strong>{t('Email')}: </strong>
      {data?.user?.email}
    </p>
    <p>
      <strong>{t('Role')}: </strong>
      {data?.user?.user_detail?.role}
    </p>
  </div>
)

const DateInfo = ({ data, t }) => (
  <div className="date-container">
    <p>
      <strong>{t('Date created')}: </strong>
      {moment(data.created_at).format('DD/MM/YYYY HH:mm')}
    </p>
    <p>
      <strong>{t('Date sent')}:</strong> {moment(data.send_at).format('DD/MM/YYYY HH:mm')}
    </p>
  </div>
)

const RecipientsInfo = ({ data, t, branches, customerTypes, customerServices }) => (
  <div>
    <span className="recipients-title">{t('Recipients')}</span>
    <div className="recipients-container">
      <p>
        <strong>{t('Branches')}: </strong>
        {branches}
      </p>
      <p>
        <strong>{t('Customer Types')}: </strong>
        {customerTypes}
      </p>
      <p>
        <strong>{t('Customer Services')}: </strong>
        {customerServices}
      </p>
      <p>
        <strong>{t('Total Recipients')}: </strong>
        {data?.accounts?.length}
      </p>
    </div>
  </div>
)

const MediaLibrary = ({ data }) => (
  <div>
    <div className="media-library-list">
      <ul>
        {data.media_library.map((mediaItem) => (
          <li key={mediaItem.id}>
            <Image
              src={generateImageUrl(
                `${mediaItem?.public_path}${mediaItem?.parent?.path}/${mediaItem?.path}`
              )}
              alt={mediaItem.title}
            />
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default PreviewMedia
PreviewMedia.displayName = 'PreviewMedia'
